<template>
    <MenuButton 
        :title="$t('nav-menu-news')" 
        :variant="routeName.startsWith('news___') || routeName.startsWith('news-about-slug___') ? 'accent' : 'outline'"
        :on-click="handleClick"
    >
        <NewsIcon class="w-6 h-6" />
    </MenuButton>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import MenuButton from "@components/ui/menu-button";
import NewsIcon from "@components/icons/outline/news-icon";

const route = useRoute();

const routeName = computed(() => route?.name || '');

const handleClick = async () => { 
    await navigateTo(translatePath(ROUTES.NEWS));
};

</script>
