<template>
  <div class="flex flex-col w-full px-5 md:px-15 py-12.5 bg-primary-dark text-light">
    <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9 border-b border-text-accent-light pb-10">
      <div v-for="(section, ids) in siteSettings.footerLinks" :key="`section_${ids}`" class="flex flex-col">
        <div class="uppercase font-bold mb-6">
          {{ $t(section.label) }}
        </div>
        <div v-for="(link, idx) in section.links" :key="`link_${idx}`" class="flex flex-col mb-3">
          <a 
            v-if="link.sidebar || link.external" 
            :href="link.external ? link.href : translatePath(link.href)"
            class="no-underline text-accent-dark transition-colors duration-200 focus:outline-none hover:text-light focus:text-light hover:underline focus:underline"
            @click.prevent="async () => await handleClick(link)"
          >
            {{ link.title ? link.title : $t(link.label) }}
          </a>
          <Link 
            v-else 
            :href="link.external ? link.href : translatePath(link.href)"
            class="no-underline text-accent-dark transition-colors duration-200 focus:outline-none hover:text-light focus:text-light hover:underline focus:underline"
            :active-class="route.path === translatePath(link.href) ? 'text-light' : ''"
          >
            {{ link.title ? link.title : $t(link.label) }}
          </Link>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-between mt-6">
      <Link
        :href="translatePath(ROUTES.HOME)"
        class="inline-flex"
      >
        <LogoSmall />
      </Link>
      <div class="flex flex-col sm:flex-row items-end justify-end">
        <LanguageSwitcher />
        <div class="text-base ml-10">
          © {{ $dayjs().format('YYYY') }} {{ $t('text-for-aquarist') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import Link from "@components/ui/link/link";
import LogoSmall from "@components/icons/logo-small";
import LanguageSwitcher from "@components/common/language-switcher";

const { $eventBus, $dayjs } = useNuxtApp();
const localePath = useLocalePath();
const route = useRoute();

const handleClick = async (link) => {
  if (link.external) {
    await navigateTo(link.href, { external: true });
  } else if (link.sidebar) {
    $eventBus.emit('sidebar:open', link.sidebar);
  } else {
    await navigateTo(translatePath(link.href));
  }
}

</script>
