<template>
    <MenuButton 
        :title="$t('text-my-favorites')" 
        :variant="routeName.startsWith('favorites___') ? 'accent' : 'outline'"
        :on-click="handleClick"
    >
        <BookmarkIcon class="w-6 h-6" />
    </MenuButton>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import MenuButton from "@components/ui/menu-button";
import BookmarkIcon from "@components/icons/bookmark-icon";

const route = useRoute();
const localePath = useLocalePath();

const routeName = computed(() => route?.name || '');

const handleClick = async () => { 
    await navigateTo(translatePath(ROUTES.FAVORITES));
};

</script>
